require('./bootstrap');

$(document).ready(function(){
    SpinnerModule()
    WelcomeFrom()

    // effect
    // effectCardFade()
    // effectTextQuoteFade()

    ClassicEditorModule()
    DisableContextMenuImages()
    CountryModule()

    // ThaiMapChartModule()

    // api
    // apiMssModule()
});

// ----------------------------------------------------------------
function WelcomeFrom() {
    console.log('\t\t\t\t MPSC \nManuscript Preservation and Study Center \n\n\tกลุ่มอนุรักษ์และศึกษาคัมภีร์พระไตรปิฎกใบลาน \n\t\t\tโครงการพระไตรปิฎกวิชาการ \n\tภายใต้การดำเนินงานของมูลนิธิสถาบันธรรมชัย')
    // console.log('Test')
}


function SpinnerModule() {
    $('#spinner')
        .delay(1000)
        .queue(function(){
            $(this).addClass('visually-hidden')
            $('#app').removeClass('visually-hidden');
        });
}

function ClassicEditorModule() {
    let ckeditor = $('.ckeditor')
    for (var i = 0; i < ckeditor.length; ++i) {
        ClassicEditor
            .create( ckeditor[i] ,{
                toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ]
            } )
            .then( editor => {
                window.editor = editor;
            } )
            .catch( error => {
                console.error( 'There was a problem initializing the editor.', error );
            } );
    }
}

function effectCardFade() {
    var CardFade = $('.card-fade');
    var Card = $('.card');

    CardFade.on('mouseover',function() {
        Card.fadeIn("slow");
    })
}

function effectTextQuoteFade() {
    var TextQuoteFade = $('.text-quote-cover');
    var TextQuote = $('.text-quote');
    var ImgQuote = $('.img-quote');
    
    // text
    TextQuoteFade.on('mouseover',function() {
        TextQuote.slideDown(function(){
            ImgQuote.fadeIn('slow');
        });
    })
}

function DisableContextMenuImages() {
    let images = $('img');
    images.on('contextmenu',function(event){
        event.preventDefault()
    });
}

function CountryModule(){
    // country
    fetch('https://restcountries.eu/rest/v2/all')
    .then(response => response.json())
    .then(data => {
        // console.log(data)

        // let data = data.data
        let selectCountry = $('select[name=country]')

        Object.entries(data).forEach(country => {
            const [abbr, detail] = country
            selectCountry.append(`<option value='${detail.alpha3Code}'>${detail.name} \t\t ${detail.nativeName}</option>`)
        })
        
        selectCountry.on('checked',function(){
            console.log(this.val())
        })
    });
}



function ThaiMapChartModule(){

    // province
    fetch('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json')
    .then(response => response.json())
    .then(data => {
        var province_list = []
        data.forEach(item => {
            province_list[item.name_en] = item.name_th
        })
        // console.log(province_list)
        
        // country
        fetch('https://code.highcharts.com/mapdata/countries/th/th-all.geo.json')
        .then(response => response.json())
        .then(data => {
            var country_list = []
            data.features.forEach(item => {
                if (item.properties['name'] != 'Bangkok Metropolis') {
                    country_list[province_list[item.properties['name']]] = item.properties['hc-key']
                } else {
                    country_list[province_list['Bangkok']] = item.properties['hc-key']
                }
            })
            // console.log(country_list)

            // mss
            // *************************************************************************************************
            // *************************************************************************************************
            // *************************************************************************************************
            fetch('https://msj.dhammakaya.network/api/get_mss_data.php?api_key=2938f3d7ba55b237c63d0ee5e3df0394')
            .then(response => response.json())
            .then(data => {
                var data_count = []
                Object.entries(data).forEach(item => {
                    const [index, info] = item
                    data_count[info.CPROVINCE] = 1
                })
                Object.entries(data).forEach(item => {
                    const [index, info] = item
                    data_count[info.CPROVINCE]++
                })

                var mss_data = []
                Object.entries(data_count).forEach((item) => {
                    mss_data.push([country_list[item[0]],item[1]])
                })
                // console.log(mss_data)
                
                // var data = [
                //     ['th-ct', 0],
                //     ['th-4255', 1],
                //     ['th-pg', 2],
                //     ['th-st', 3],
                //     ['th-kr', 4],
                //     ['th-sa', 5],
                //     ['th-tg', 6],
                //     ['th-tt', 7],
                //     ['th-pl', 8],
                //     ['th-ps', 9],
                //     ['th-kp', 10],
                //     ['th-pc', 11],
                //     ['th-sh', 12],
                //     ['th-at', 13],
                //     ['th-lb', 14],
                //     ['th-pa', 15],
                //     ['th-np', 16],
                //     ['th-sb', 17],
                //     ['th-cn', 18],
                //     ['th-bm', 19],
                //     ['th-pt', 20],
                //     ['th-no', 21],
                //     ['th-sp', 22],
                //     ['th-ss', 23],
                //     ['th-sm', 24],
                //     ['th-pe', 25],
                //     ['th-cc', 26],
                //     ['th-nn', 27],
                //     ['th-cb', 28],
                //     ['th-br', 29],
                //     ['th-kk', 30],
                //     ['th-ph', 31],
                //     ['th-kl', 32],
                //     ['th-sr', 33],
                //     ['th-nr', 34],
                //     ['th-si', 35],
                //     ['th-re', 36],
                //     ['th-le', 37],
                //     ['th-nk', 38],
                //     ['th-ac', 39],
                //     ['th-md', 40],
                //     ['th-sn', 41],
                //     ['th-nw', 42],
                //     ['th-pi', 43],
                //     ['th-rn', 44],
                //     ['th-nt', 45],
                //     ['th-sg', 46],
                //     ['th-pr', 47],
                //     ['th-py', 48],
                //     ['th-so', 49],
                //     ['th-ud', 50],
                //     ['th-kn', 51],
                //     ['th-tk', 52],
                //     ['th-ut', 53],
                //     ['th-ns', 54],
                //     ['th-pk', 55],
                //     ['th-ur', 56],
                //     ['th-sk', 57],
                //     ['th-ry', 58],
                //     ['th-cy', 59],
                //     ['th-su', 60],
                //     ['th-nf', 61],
                //     ['th-bk', 62],
                //     ['th-mh', 63],
                //     ['th-pu', 64],
                //     ['th-cp', 65],
                //     ['th-yl', 66],
                //     ['th-cr', 67],
                //     ['th-cm', 68],
                //     ['th-ln', 69],
                //     ['th-na', 70],
                //     ['th-lg', 71],
                //     ['th-pb', 72],
                //     ['th-rt', 73],
                //     ['th-ys', 74],
                //     ['th-ms', 75],
                //     ['th-un', 76],
                //     ['th-nb', 77]
                // ];
                
                // Create the chart
                Highcharts.mapChart('ThaiMapChartModule', {
                    chart: {
                        map: 'countries/th/th-all',
                        height: 800,
                        width: 800
                    },
                
                    title: {
                        text: 'Thailand'
                    },
                
                    mapNavigation: {
                        enabled: true,
                        buttonOptions: {
                            verticalAlign: 'bottom'
                        }
                    },
                
                    colorAxis: {
                        min: 0
                    },
                
                    series: [{
                        data: mss_data,
                        states: {
                            hover: {
                                color: '#BADA55'
                            }
                        },
                        dataLabels: {
                            enabled: false,
                            format: '{point.name}'
                        }
                    }]
                });
            });

        });
    });
    


}